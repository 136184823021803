try{
  const open_btn = document.querySelector('#open-sidebar-btn-js');
  const close_btn = document.querySelector('#close-sidebar-btn-js');
  var sidebar = document.querySelector('.sidebar-container');

  open_btn.addEventListener('click', ()=>{
    sidebar.classList.add('active')
  });
  close_btn.addEventListener('click', ()=>{
    sidebar.classList.remove('active')
  });
} catch(e){

}